<template>
  <div class="footer">
    <div class="navigation">
      <div class="white-bg">
        <nuxt-link :to="'/contactUs'">
          <el-button type="text-black" :size="size" :btn-min-width="minWidth"
            >Contact Us</el-button
          >
        </nuxt-link>
        <a
          href="https://creditsaisonin-talent.freshteam.com/jobs"
          target="_blank"
        >
          <el-button type="text-black" :size="size" :btn-min-width="minWidth"
            >Careers</el-button
          >
        </a>
        <nuxt-link :to="'/regulatory'">
          <el-button type="text-black" :size="size" :btn-min-width="minWidth"
            >Regulatory</el-button
          >
        </nuxt-link>
        <a href="https://corporate.saisoncard.co.jp/en/" target="_blank">
          <el-button type="text-black" :size="size" :btn-min-width="minWidth"
            >CS Japan</el-button
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    minWidth: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/theme/color';
@import '~/assets/scss/main';

$--z-index: 3;

.footer {
  position: fixed;
  bottom: 40px;
  left: 32px;
  width: calc(100% - 130px);
  display: flex;
  align-items: center;
  z-index: $--z-index;
  @include for-phone-portrait {
    width: calc(100% - 40px);
    left: 20px;
  }
  .navigation {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    @include for-tablet-portrait {
      padding-bottom: 15px;
    }
    @include for-tablet-landscape {
      padding-bottom: 15px;
    }
    .white-bg {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
