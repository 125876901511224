<template>
  <div :class="type">
    <div
      class="box grey"
      :style="geryRotate"
      :height="height"
      :width="width"
    ></div>
    <div class="box" :style="colorRotate" :height="height" :width="width"></div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'green'
    },
    colorRotation: { type: Number, default: 0 },
    greyRotation: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
    width: { type: Number, default: 0 }
  },
  data() {
    return {}
  },
  computed: {
    colorRotate() {
      if (this.colorRotation)
        return `transform: rotate(${this.colorRotation}deg)`
      else return null
    },
    geryRotate() {
      if (this.greyRotation) return `transform: rotate(${this.greyRotation}deg)`
      else return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/main';

.box {
  position: absolute;
  top: 0;
  left: 0;
}
.grey {
  background-color: #f1f2f4 !important;
}
.green {
  .box {
    border-radius: 100px;
    width: 460.98px;
    height: 632.01px;
    transform: rotate(10deg);
    background-color: #bee2c4;
    @include for-phone-portrait {
      border-radius: 30px;
      width: 160.98px;
      height: 232.01px;
    }
    @include for-phone-landscape {
      border-radius: 30px;
      width: 160.98px;
      height: 232.01px;
    }
    @include for-tablet-portrait {
      border-radius: 60px;
      width: 360.98px;
      height: 482.01px;
    }
    @include for-tablet-landscape {
      border-radius: 60px;
      width: 360.98px;
      height: 482.01px;
    }
  }
  .grey {
    height: 670.52px;
    transform: rotate(20deg);
    @include for-phone-portrait {
      height: 232.01px;
    }
    @include for-phone-landscape {
      height: 232.01px;
    }
    @include for-tablet-portrait {
      border-radius: 60px;
      width: 360.98px;
      height: 482.01px;
    }
    @include for-tablet-landscape {
      border-radius: 60px;
      width: 360.98px;
      height: 482.01px;
    }
  }
}
.turquoise {
  .box {
    border-radius: 90px;
    width: 545px;
    height: 389px;
    transform: rotate(345deg);
    background-color: #bbe0e3;
    @include for-phone-portrait {
      border-radius: 40px;
      width: 185px;
      height: 229px;
    }
    @include for-phone-landscape {
      border-radius: 40px;
      width: 185px;
      height: 229px;
    }
    @include for-tablet-portrait {
    }
  }
  .grey {
    transform: rotate(330deg);
  }
}
.yellow {
  .box {
    border-radius: 90px;
    width: 443.17px;
    height: 443.17px;
    transform: rotate(10deg);
    background-color: #f2e6b5;
    @include for-phone-portrait {
      border-radius: 30px;
      width: 243.17px;
      height: 243.17px;
    }
    @include for-phone-landscape {
      border-radius: 30px;
      width: 243.17px;
      height: 243.17px;
    }
    @include for-tablet-portrait {
    }
  }
  .grey {
    transform: rotate(355deg);
  }
}
.pink {
  .box {
    border-radius: 55px;
    width: 279.63px;
    height: 209px;
    transform: rotate(350deg);
    background: #ebbfbb;
    @include for-phone-portrait {
      border-radius: 25px;
      width: 199.63px;
      height: 139px;
    }
    @include for-phone-landscape {
      border-radius: 25px;
      width: 199.63px;
      height: 139px;
    }
    @include for-tablet-portrait {
    }
  }
  .grey {
    transform: rotate(340deg);
  }
}
</style>
